import request from '@/../utils/request'
const api_name = '/iotechserver/OperLogController'

export default {

    getLogFromUser(current,limit){
        return request({
            url: `${api_name}/getLogFromUser/${current}/${limit}`,
            method: 'get'
        })
    },
    getLogFromUserByName(name,current,limit){
        return request({
            url: `${api_name}/getLogFromUser/${name}/${current}/${limit}`,
            method: 'get'
        })
    },
   

}