<template>
  <div id="app">
    <!-- <el-row>
      <el-col :span="24" class="title">
        <el-page-header @back="goBack" content="操作日志"> </el-page-header>
      </el-col>
    </el-row> -->
    <!-- 账号查询 -->
    <el-row :gutter="10" style="margin:10px 0">
      <el-col :span="6">
        <el-input v-model="accountValue" placeholder="请输入要查询的操作账号"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="success" size="middle" @click="queryAccount">查询</el-button>
      </el-col>
      <el-col :span="6" class="rows" style="float:right;width:10%;">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-table :data="tableData" border highlight-current-row>
      <el-table-column fixed type="index" label="序号" align="center" width="70">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('操作账号')" prop="name" label="操作账号" align="center">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('操作模块')" prop="module" label="操作模块" align="center">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('操作类型')" prop="type" label="操作类型" align="center">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('IP')" prop="ip" label="IP" align="center"> </el-table-column>
      <el-table-column v-if="tableTitle.includes('接口')" prop="apiName" label="接口" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('创建时间')" prop="creatTime" label="创建时间" align="center"></el-table-column>
    </el-table>

    <el-pagination background :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="queryAccount"></el-pagination>
  </div>
</template>

<script>
import logOperation from "../../api/manageApi/LogOperation";
import logOperationRule from "../../api/manageApi/LogOperationRule";
export default {
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '操作账号',
      }, {
        id: 1,
        title: '操作模块',
      }, {
        id: 2,
        title: '操作类型',
      }, {
        id: 3,
        title: 'IP',
      }, {
        id: 4,
        title: '接口',
      }, {
        id: 5,
        title: '创建时间',
      }],
      tableTitle: ['操作账号', '操作模块', '操作类型', 'IP', '接口', '创建时间'],


      current: 1,
      limit: 8,
      total: 0,

      accountValue: "",
      logRule: [],

      tableData: [],
    };
  },
  created() {
    this.getAllLogRuleInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('操作账号')
      } else {
        this.tableTitle = value
      }
    },

    // 查询所有日志
    getAllLogRuleInfo() {
      var _this = this;
      logOperationRule.selectAllLogRule().then((res) => {
        console.log(res);
        _this.logRule = res.data.data.list;
        console.log(_this.logRule);
        _this.queryAccount();
      });
      // logRule
    },

    // 查询按钮
    queryAccount() {
      var _this = this;
      if (this.accountValue == "") {
        logOperation.getLogFromUser(this.current, this.limit).then((res) => {
          console.log();
          this.total = res.data.data.total;
          _this.tableData = res.data.data.rows;
          for (let j in this.tableData) {
            this.tableData[j]["module"] = "";
            this.tableData[j]["type"] = "";
            for (let i in this.logRule) {
              if (
                this.tableData[j]["apiName"].indexOf(this.logRule[i]["name"]) !=
                -1
              ) {
                if (this.logRule[i]["type"] == "操作") {
                  this.tableData[j]["type"] = this.logRule[i]["value"];
                } else {
                  this.tableData[j]["module"] = this.logRule[i]["value"];
                }
              }
              if (
                this.tableData[j]["module"] != "" &&
                this.tableData[j]["type"] != ""
              )
                break;
            }
          }
        });
      } else {
        logOperation
          .getLogFromUserByName(this.accountValue, this.current, this.limit)
          .then((res) => {
            this.total = res.data.data.total;
            _this.tableData = res.data.data.rows;
            for (let j in this.tableData) {
              this.tableData[j]["module"] = "";
              this.tableData[j]["type"] = "";
              for (let i in this.logRule) {
                if (
                  this.tableData[j]["apiName"].indexOf(
                    this.logRule[i]["value"]
                  ) != -1
                ) {
                  if (this.logRule[i]["type"] == "操作") {
                    this.tableData[j]["type"] = this.logRule[i]["value"];
                  } else {
                    this.tableData[j]["module"] = this.logRule[i]["value"];
                  }
                }
                if (
                  this.tableData[j]["module"] != "" &&
                  this.tableData[j]["type"] != ""
                )
                  break;
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>