import request from '@/../utils/request'
const api_name = '/iotechserver/OperLogRuleController'

export default {

    selectAllLogRule(){
        return request({
            url: `${api_name}/selectAllLogRule`,
            method: 'get'
        })
    },
   

}